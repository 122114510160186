<template>
    <section>
        <section class="d-flex align-center justify-end">
            <v-menu v-model="menu" :close-on-content-click="false" offset-y left transition="slide-y-transition">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" dense outlined class="mr-3" color="primary">
                        Filter <v-icon small right>mdi-filter</v-icon>
                    </v-btn>
                </template>
                <v-card class="pa-3" width="300">
                    <label class="caption">CATEGORY</label>
                    <v-select
                        :items="tabs"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="filter.category"
                        item-text="text"
                        item-value="id"
                        class="general-custom-field poppins mb-3 f12"
                    />
                    <label class="caption">STATUS</label>
                    <v-select
                        :items="ticketStatus"
                        outlined
                        dense
                        hide-details="auto"
                        v-model="filter.status"
                        item-text="text"
                        item-value="id"
                        class="general-custom-field poppins mb-3 f12"
                        width="100"
                    />

                    <label class="caption">DATE RECEIVED FROM:</label>
                    <v-menu
                        v-model="modelFrom"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="filter.date_from"
                            prepend-icon="mdi-calendar"
                            dense
                            outlined
                            hide-details
                            class="mb-3"
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="filter.date_from"
                            @input="modelFrom = false"
                        ></v-date-picker>
                    </v-menu>

                    <label class="caption">DATE RECEIVED TO:</label>
                    <v-menu
                        v-model="modelTo"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="filter.date_to"
                            prepend-icon="mdi-calendar"
                            dense
                            outlined
                            hide-details
                            v-bind="attrs"
                            v-on="on"
                            class="mb-3"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="filter.date_to"
                            @input="modelTo = false"
                        ></v-date-picker>
                    </v-menu>

                    <v-divider />

                    <v-btn small outlined color="primary" block dense class="mt-2" @click="clearFilters()">
                        CLEAR FILTERS
                    </v-btn>    
                    <v-btn small color="primary" block dense class="mt-2" @click="() => { getTickets(), getTicketCount() }">
                        APPLY
                    </v-btn>    
                </v-card>
            </v-menu>
            <v-btn :loading="exportLoading" color="primary" right outlined class="poppins my-2" @click="exportTickets()">
                <v-icon left>
                mdi-export-variant
                </v-icon>
                Export List
            </v-btn>
        </section>
        <v-divider class="my-3"/>
        <v-progress-linear
            v-if="loading"
                indeterminate
                color="primary"
                rounded
                height="4"
                class="mb-3"
        ></v-progress-linear>
        <v-row class="mb-5">
            <v-col cols="12" md="4" v-for="(item, i) in items" :key="i" justify="center">
                <TopCard :item="item"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="$vuetify.breakpoint.smAndDown ? ( selected ? false : true) : true" :cols="$vuetify.breakpoint.smAndDown ? '12' : (selected ? '4' : '12')">
                <v-card class="custom-border border pa-2">
                    <v-card-text>
                        <div v-if="!loading && tickets.length === 0" class="poppins fw600 f13 secondary-4--text font-italic"> No Tickets Available. </div>
                        <!-- <v-tabs show-arrows v-model="tab" slider-color="primary" role="tablist" background-color="transparent">
                            <v-tab v-for="tab in tabs" :key="tab.id" class="poppins text-wrap rounded" active-class=" primary--text" >
                                <v-badge
                                    v-if="tab.count > 0"
                                    overlap
                                    inline
                                    bordered
                                    :color="tab.count === 0 ? 'transparent' : 'primary'"
                                    :content="tab.count"
                                    :offset-x="15"
                                    style="'text'">
                                    {{  tab.text }}
                                </v-badge>
                                <span v-else>
                                    {{  tab.text }}
                                </span>
                            </v-tab>
                        </v-tabs> -->
                        <v-card outlined v-for="ticket in tickets" :key="ticket.id" class=" hover:cursor-pointer mb-3" :elevation="selected ? (selected.id === ticket.id ? '1' : '') : ''" @click="selected = ticket">
                            
                            
                           
                            <!--  <section :class="$vuetify.breakpoint.smAndDown ? 'd-flex flex-column' : 'd-flex flex-row justify-space-between'"> -->
                                <v-alert
                                    border="right"
                                    :color="ticketStatus[ticket.status].color"
                                    colored-border
                                    class="mb-0"
                                    tile
                                >
                                    <section class="primary--text poppins fw500 mt-0" order="2">
                                        <v-avatar size="20" class="mr-2">
                                            <v-img 
                                            :src="ticket.user.image? ticket.user.image.url : require('@/assets/default-photo.png')"
                                            v-on:error="require('@/assets/default-photo.png')"></v-img>
                                        </v-avatar>
                                        {{ ticket.submitted_by }}
                                    </section>
                                </v-alert>
                                
                                <!-- <v-card-text class="d-flex flex-column" order="1">
                                    <v-icon :color="ticketStatus[ticket.status].color" small class="ml-auto">
                                        mdi-circle
                                    </v-icon>
                                </v-card-text> -->
                            <v-chip small class="f9 mx-3 mt-3 mb-3 text-uppercase" > {{  ticket.concern_category  }}</v-chip>
                            <v-divider />
                            <v-card-text  style="height:40px;" class="overflow-y-hidden ellipsis">
                                {{ ticket.content.length === 0 ? '-' : ticket.content }} 
                            </v-card-text>
                            <v-card-text class="text-right f11">
                                {{$dateFormat.dayDateTime(ticket.created_at)}}
                            </v-card-text>
                        </v-card>
                        <!-- <v-data-table
                            :headers="admin_ticket_tbl"
                            :items="tickets"
                            :loading="loading"
                            class="elevation-1 custom-border poppins"
                            hide-default-footer>
                            <template v-slot:item.id="{ item }">
                                <v-btn text class="fw400" color="primary" @click="openDialog(item)">
                                    EDU-{{item.id}}
                                </v-btn>
                            </template>
                            <template v-slot:item.status="{ item }">
                                <span v-if="item.status === 2">
                                    <v-icon color="gray" small class="mr-2">
                                        mdi-circle
                                    </v-icon>
                                    PENDING
                                </span>
                            </template>
                            <template v-slot:item.created_at="{ item }">
                                {{$dateFormat.dayDateTime(item.created_at)}}
                            </template>
                        </v-data-table> -->
                        
                        
                        <FormPagination 
                            :pageCount="pageCount" 
                            :page="page"
                            :paginate="paginate"
                            @page="(e) => {page = e, getTickets()}" 
                            @paginate="(e) => {paginate = e, page = 1, getTickets()}"/>
                        
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col v-if="selected" :cols="$vuetify.breakpoint.smAndDown ? '12' : (selected ? '8' : '12')">
                
                <v-card class="custom-border border pa-5">
                    <div class="d-flex justify-end">
                        <v-btn icon small @click="selected = null">
                            <v-icon small>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </div>
                    <TicketMessages :ticket="selected" @getTickets="() => {getTickets(), getTicketCount()}"/>
                </v-card>
                <!-- <v-card flat color='secondary-5' class="pa-5">
                    
                </v-card> -->
            </v-col>
        </v-row>
        
    </section>
</template>

<style>
.wrap {
    overflow-wrap: anywhere !important;
    word-wrap: break-word !important;
    word-break: normal !important;
    hyphens: auto !important;
}
</style>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import { admin_ticket_tbl } from '@/constants/tblheaders/ticket'
import TicketDialog from '@/components/admin/ticket/TicketDialog.vue';
import TicketMessages from '@/components/admin/ticket/TicketMessages.vue';
import { mapState, mapActions } from 'vuex';
import { ticketStatus } from '@/constants/colors'
import TopCard from '@/components/reusable/TopCard.vue'

export default defineComponent({
    components: {
        TopCard,
        TicketDialog,
        TicketMessages
    },
    data: () => ({
        filter: {
            category: '',
            status: '',
            date_from: '',
            date_to: ''
        },
        admin_ticket_tbl,
        loading: true,
        exportLoading: false, 
        dialog: false,
        selectedTicket: null,
        paginate: '10',
        page: 1,
        pageCount: 1,
        totalCount: 0,
        selected: null,
        items: [
            {
                title: 'Open Tickets',
                count: '-',
                color: '#2196F333', 
                outline: '#2196F3'
            },
            {
                title: 'Pending Tickets',
                count: '-',
                color: '#F9A82533',
                outline: '#F9A825'
            },
            {
                title: 'On Hold Tickets',
                count: '-',
                color: '#9E9E9E33',
                outline: '#9E9E9E'
            },
            {
                title: 'Solved Tickets',
                count: '-',
                color: '#388E3C33',
                outline: '#388E3C'
            },
            {
                title: 'Closed Tickets',
                count: '-',
                color: '#21212133',
                outline: '#212121'
            },
        ],
        tabs: [ 
            {
                text: "All",
                id: "",
                count: 0
            },
            {
                text: "Platform",
                id: "platform",
                count: 0
            },
            {
                text: "Content",
                id: "content",
                count: 0
            },
            {
                text: "Assessment",
                id: "assessment",
                count: 0
            },
            {
                text: "Others",
                id: "others",
                count: 0
            }
        ],
        menu: false,
        ticketStatus,
        modelFrom: false,
        modelTo: false
    }),
    methods: {
        ...mapActions('admin', ['getTicketsAction', 'getTicketCountAction', 'ticketExportAction']),

        openDialog(item){
            this.dialog = true
            this.selectedTicket = item
        },

        closeDialog(){
            this.dialog=false
        },

        getTickets(){
            this.loading = true
            this.getTicketsAction({ ...this.filter, paginate: Number(this.paginate), page: this.page }).then(res => {
                this.loading = false
                this.paginate = String(res.per_page)
                this.page = res.current_page
                this.pageCount = res.last_page
                this.totalCount = res.total
            }).catch(() => this.loading = false)
        },

        getTicketCount(){
            this.loading = true

            this.getTicketCountAction(this.filter).then(res => {
                this.items[0].count = res.total_open_tickets
                this.items[1].count = res.total_pending_tickets
                this.items[2].count = res.total_on_hold_tickets
                this.items[3].count = res.total_solved_tickets
                this.items[4].count = res.total_closed_tickets
            }).catch(() => this.loading = false)
        },

        getAllTickets(){
            this.loading = true
            this.getTicketCountAction(this.filter).then(res => {
                this.items[0].count = res.total_open_tickets
                this.items[1].count = res.total_pending_tickets
                this.items[2].count = res.total_on_hold_tickets
                this.items[3].count = res.total_solved_tickets
                this.items[4].count = res.total_closed_tickets
                this.getTickets() 
            }).catch(() => {
                this.loading = false
            })
        },

        exportTickets(){
            this.exportLoading = true
            this.ticketExportAction(this.filter).then(() => {
                this.exportLoading = false
            }).catch(() => {
                this.exportLoading = false
            })
        },

        clearFilters(){
            this.filter = {
                category: '',
                status: '',
                date_from: '',
                date_to: ''
            }
        }
    },
    computed: {
        ...mapState('admin', {
            tickets: (state) => state.tickets
        }),

        getHighlight(){
            return this.$vuetify.theme.currentTheme['primary-1']
        },
    },
    mounted(){
        if(Object.values(this.$route.query).length == 0){
            this.$router.replace({ query: { page: 1, paginate: Number(this.paginate), category: this.tab, timestamp: Date.now(),  } });
        } else {
            let { page, paginate, category } = this.$route.query

            if (page) {
                this.page = Number(page)
            } 
            if (paginate) {
                this.paginate = paginate
            }
            if (category) {
                this.tab = category
            }
        }
        this.getTicketCountAction(this.filter).then(res => {
            this.items[0].count = res.total_open_tickets
            this.items[1].count = res.total_pending_tickets
            this.items[2].count = res.total_on_hold_tickets
            this.items[3].count = res.total_solved_tickets
            this.items[4].count = res.total_closed_tickets
            this.getTickets() 
        }).catch(() => {
            this.loading = false
        })
        
    },
    watch:{
        type(val){
            this.getTickets()        
        },

        paginate(val) {
            this.page = 1
            this.getTickets()
        },

        page(val) {
            this.getTickets()
        }
    }
})
</script>
