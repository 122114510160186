const ticketStatus = [
    {
        id: '',
        value: '',
        text: 'All',
        color: 'pink'
    },
    {
        id: 1,
        value: 1,
        text: 'Open',
        color: 'blue'
    },
    {
        id: 2,
        value: 2,
        text: 'Pending',
        color: 'yellow'
    },
    {
        id: 3,
        value: 3,
        text: 'On Hold',
        color: 'gray'
    },
    {
        id: 4,
        value: 4,
        text: 'Solved',
        color: 'green'
    },
    {
        id: 5,
        value: 5,
        text: 'Closed',
        color: 'black'
    },
]

export { ticketStatus }